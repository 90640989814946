import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Shenzhen Starnoon Technology Co., LTD: Stretch and False Ceiling Experts
			</title>
			<meta name={"description"} content={"Welcome to Shenzhen Starnoon Technology Co., LTD, the home of innovative and stylish stretch and false ceiling solutions."} />
			<meta property={"og:title"} content={"Home | Shenzhen Starnoon Technology Co., LTD: Stretch and False Ceiling Experts"} />
			<meta property={"og:description"} content={"Welcome to Shenzhen Starnoon Technology Co., LTD, the home of innovative and stylish stretch and false ceiling solutions."} />
			<meta property={"og:image"} content={"https://vernioliever.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vernioliever.com/img/9119630.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vernioliever.com/img/9119630.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vernioliever.com/img/9119630.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vernioliever.com/img/9119630.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vernioliever.com/img/9119630.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vernioliever.com/img/9119630.png"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://vernioliever.com/img/1.jpg) center/cover"
			padding="60px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Box margin="-16px -16px -16px -16px" display="flex">
				<Box display="flex" padding="16px 16px 16px 16px" width="75%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
							Welcome to Shenzhen Starnoon Technology Co., LTD, the home of innovative and stylish stretch and false ceiling solutions. Specializing in interior ceiling renovation and design, we transform ordinary spaces into extraordinary ones with our unique and elegant ceiling creations.
						</Text>
						<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0">
							Shenzhen Starnoon Technology Co., LTD
						</Text>
					</Box>
				</Box>
			</Box>
			<Box text-align="center" margin="96px 0 0 0">
				<Text margin="8px 0" text-transform="uppercase">
					Who we are
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="30%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Why Shenzhen Starnoon Technology Co., LTD?
				</Text>
			</Box>
			<Box
				display="flex"
				width="70%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Bespoke Designs: Our designs are as unique as your spaces. From sleek stretch ceilings to intricate false ceiling patterns, we tailor each project to your vision.
					<br />
					<br />
					Quality Materials: We use only the finest materials, ensuring durability, safety, and an impeccable finish for every ceiling we craft.
				</Text>
			</Box>
			<Box
				display="grid"
				width="100%"
				margin="80px 0px 0px 0px"
				lg-margin="40px 0px 0px 0px"
				grid-gap="32px"
				sm-grid-template-columns="1fr"
			>
				<Image
					src="https://vernioliever.com/img/2.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="30px 0 30px 0" md-padding="40px 0 40px 0" quarkly-title="About-13">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="grid"
				grid-gap="32px"
				sm-grid-gap="16px"
				grid-template-columns="4fr 5fr"
				align-items="center"
				width="50%"
				lg-width="100%"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Image
					src="https://vernioliever.com/img/3.jpg"
					object-fit="cover"
					width="100%"
					height="80%"
					lg-height="100%"
				/>
				<Image
					src="https://vernioliever.com/img/4.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					max-height="460px"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				padding="42px 42px 42px 42px"
				lg-width="100%"
				md-padding="24px 24px 24px 24px"
				md-margin="24px 0px 0px 0px"
				sm-align-items="flex-start"
				sm-padding="24px 24px 24px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--base"
					lg-text-align="center"
					text-transform="uppercase"
					letter-spacing="2px"
					sm-text-align="left"
				>
					Advantages of Choosing Us
				</Text>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Expert Installation:{" "}
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="center"
					sm-text-align="left"
				>
					Our team of skilled professionals guarantees a smooth, efficient, and precise installation process, with minimal disruption to your daily life.
				</Text>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Aesthetic and Functional:{" "}
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="center"
					sm-text-align="left"
				>
					Our ceilings are more than just beautiful. They're designed to enhance room acoustics and integrate seamlessly with lighting solutions.
				</Text>
				<Link
					href="/contacts"
					color="--lightD1"
					text-decoration-line="initial"
					padding="11px 24px 12px 24px"
					background="--color-indigo"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					border-radius="36px"
					letter-spacing="1px"
				>
					Contact Us
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});